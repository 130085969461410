<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Company Center
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="CompanyCenterAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="companyCenter.code"
            :rules="{ required: true }"
            name="Code"
            label="Code"
            trim />
          <TextFieldInput
            v-model="companyCenter.name"
            :rules="{ required: true }"
            name="Name"
            label="Name"
            trim />
          <TextFieldInput
            v-model="companyCenter.remark"
            name="Remark"
            label="Remark"
            trim />
          <SelectField
            v-model="companyCenter.companyGroupId"
            :options="companyGroupOptions"
            name="Company Group"
            form-label="Company Group"
            label-option="name"
            return-value="id" />
          <SelectField
            v-model="companyCenter.companyDepartmentId"
            :options="companyDepartmentOptions"
            name="Company Department"
            form-label="Company Department"
            label-option="name"
            return-value="id" />
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import SelectField from '@/components/Form/SelectField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
// import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    TextFieldInput,
    SelectField
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    companyGroupOptions: {
      type: Array,
      default: () => []
    },
    companyDepartmentOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      roleData: [
        { label: 'Agent', value: 'agent' },
        { label: 'Manager', value: 'manager' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'SuperAdmin', value: 'super admin' }
      ],
      initialCompanyCenter: {
        code: '',
        name: '',
        remark: '',
        companyGroupId: '',
        companyDepartmentId: ''
      },
      companyCenter: {
        code: '',
        name: '',
        remark: '',
        companyGroupId: '',
        companyDepartmentId: ''
      }
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-company-center', this.companyCenter)
    },
    resetForm () {
      this.companyCenter = { ...this.initialCompanyCenter }
      this.$refs.CompanyCenterAddForm.reset()
    }
  }
}
</script>

<style>

.btn-submit {
  min-width: 134px;
}
</style>
